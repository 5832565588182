import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Detail_sidebar_on_dark from './components/application-ui/page-examples/detail-screens/sidebar_on_dark';
import Detail_stacked from './components/application-ui/page-examples/detail-screens/stacked';
import Home_sidebar_on_dark from './components/application-ui/page-examples/home-screens/sidebar_on_dark';
import Home_stacked from './components/application-ui/page-examples/home-screens/stacked';
import Setting_sidebar_on_dark from './components/application-ui/page-examples/settings-screens/sidebar_on_dark';
import Setting_stacked from './components/application-ui/page-examples/settings-screens/stacked';

import Cat_with_image_header_and_detail_product_grid from './components/ecommerce/page-examples/category-pages/with_image_header_and_detail_product_grid';
import Cat_with_large_images_and_filters_sidebar from './components/ecommerce/page-examples/category-pages/with_large_images_and_filters_sidebar';
import Cat_with_product_grid_and_pagination from './components/ecommerce/page-examples/category-pages/with_product_grid_and_pagination';
import Cat_with_text_header_and_image_product_grid from './components/ecommerce/page-examples/category-pages/with_text_header_and_image_product_grid';
import Cat_with_text_header_and_simple_product_grid from './components/ecommerce/page-examples/category-pages/with_text_header_and_simple_product_grid';
import Checkout_multi_step from './components/ecommerce/page-examples/checkout-pages/multi_step';
import Checkout_single_step_with_order_summary from './components/ecommerce/page-examples/checkout-pages/single_step_with_order_summary';
import Checkout_split_with_order_summary from './components/ecommerce/page-examples/checkout-pages/split_with_order_summary';
import Checkout_with_mobile_order_summary_overlay from './components/ecommerce/page-examples/checkout-pages/with_mobile_order_summary_overlay';
import Checkout_with_order_summary_sidebar from './components/ecommerce/page-examples/checkout-pages/with_order_summary_sidebar';
import Order_simple_with_full_order_details from './components/ecommerce/page-examples/order-detail-pages/simple_with_full_order_details';
import Order_with_large_images_and_progress_bars from './components/ecommerce/page-examples/order-detail-pages/with_large_images_and_progress_bars';
import Order_with_progress_bars from './components/ecommerce/page-examples/order-detail-pages/with_progress_bars';
import History_simple from './components/ecommerce/page-examples/order-history-pages/simple';
import History_with_invoice_list from './components/ecommerce/page-examples/order-history-pages/with_invoice_list';
import History_with_invoice_list_and_quick_actions from './components/ecommerce/page-examples/order-history-pages/with_invoice_list_and_quick_actions';
import History_with_invoice_panels from './components/ecommerce/page-examples/order-history-pages/with_invoice_panels';
import History_with_invoice_tables from './components/ecommerce/page-examples/order-history-pages/with_invoice_tables';
import Product_with_expandable_product_details from './components/ecommerce/page-examples/product-pages/with_expandable_product_details';
import Product_with_featured_details from './components/ecommerce/page-examples/product-pages/with_featured_details';
import Product_with_image_grid from './components/ecommerce/page-examples/product-pages/with_image_grid';
import Product_with_related_products from './components/ecommerce/page-examples/product-pages/with_expandable_product_details';
import Product_with_tabs_and_related_products from './components/ecommerce/page-examples/product-pages/with_tabs_and_related_products';
import Cart_simple_with_policy_grid from './components/ecommerce/page-examples/shopping-cart-pages/simple_with_policy_grid';
import Cart_with_policy_grid_and_extended_summary from './components/ecommerce/page-examples/shopping-cart-pages/with_policy_grid_and_extended_summary';
import Cart_with_related_products from './components/ecommerce/page-examples/shopping-cart-pages/with_related_products';
import Storefront_with_dark_nav_and_footer from './components/ecommerce/page-examples/storefront-pages/with_dark_nav_and_footer';
import Storefront_with_image_tiles_and_feature_sections from './components/ecommerce/page-examples/storefront-pages/with_image_tiles_and_feature_sections';
import Storefront_with_offers_and_testimonials from './components/ecommerce/page-examples/storefront-pages/with_offers_and_testimonials';
import Storefront_with_overlapping_image_tiles_and_perks from './components/ecommerce/page-examples/storefront-pages/with_overlapping_image_tiles_and_perks';

import About_dark from './components/marketing/page-examples/about-pages/dark';
import About_with_image_tiles from './components/marketing/page-examples/about-pages/with_image_tiles';
import About_with_timeline_and_stats from './components/marketing/page-examples/about-pages/with_timeline_and_stats';
import Landing_with_background_image_hero_and_pricing_section from './components/marketing/page-examples/landing-pages/with_background_image_hero_and_pricing_section';
import Landing_with_large_screenshot_and_testimonial from './components/marketing/page-examples/landing-pages/with_large_screenshot_and_testimonial';
import Landing_with_mobile_screenshot_and_testimonials_grid from './components/marketing/page-examples/landing-pages/with_mobile_screenshot_and_testimonials_grid';
import Landing_with_screenshots_and_stats from './components/marketing/page-examples/landing-pages/with_screenshots_and_stats';
import Pricing_with_comparison_table from './components/marketing/page-examples/pricing-pages/with_comparison_table';
import Pricing_with_four_tiers from './components/marketing/page-examples/pricing-pages/with_four_tiers';
import Pricing_with_three_tiers_and_testimonials from './components/marketing/page-examples/pricing-pages/with_three_tiers_and_testimonials';

function Home() {
  return <h1>Home Page</h1>;
}
function About() {
  return <h1>About Page</h1>;
}
function Contact() {
  return <h1>Contact Page</h1>;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
        
          /* Application-UI */
          <Route path="detail1" element={<Detail_sidebar_on_dark />} />
          <Route path="detail2" element={<Detail_stacked />} />
          
          <Route path="home1" element={<Home_sidebar_on_dark />} />
          <Route path="home2" element={<Home_stacked />} />
          
          <Route path="settings1" element={<Setting_sidebar_on_dark />} />
          <Route path="settings2" element={<Setting_stacked />} />
          
          /* Ecommerce */
          <Route path="category1" element={<Cat_with_image_header_and_detail_product_grid />} />
          <Route path="category2" element={<Cat_with_large_images_and_filters_sidebar />} />
          <Route path="category3" element={<Cat_with_product_grid_and_pagination />} />
          <Route path="category4" element={<Cat_with_text_header_and_image_product_grid />} />
          <Route path="category5" element={<Cat_with_text_header_and_simple_product_grid />} />
          
          <Route path="checkout1" element={<Checkout_multi_step />} />
          <Route path="checkout2" element={<Checkout_single_step_with_order_summary />} />
          <Route path="checkout3" element={<Checkout_split_with_order_summary />} />
          <Route path="checkout4" element={<Checkout_with_mobile_order_summary_overlay />} />
          <Route path="checkout5" element={<Checkout_with_order_summary_sidebar />} />
          
          <Route path="order1" element={<Order_simple_with_full_order_details />} />
          <Route path="order2" element={<Order_with_large_images_and_progress_bars />} />
          <Route path="order3" element={<Order_with_progress_bars />} />
          
          <Route path="history1" element={<History_simple />} />
          <Route path="history2" element={<History_with_invoice_list />} />
          <Route path="history3" element={<History_with_invoice_list_and_quick_actions />} />
          <Route path="history4" element={<History_with_invoice_panels />} />
          <Route path="history5" element={<History_with_invoice_tables />} />

          <Route path="product1" element={<Product_with_expandable_product_details />} />
          <Route path="product2" element={<Product_with_featured_details />} />
          <Route path="product3" element={<Product_with_image_grid />} />
          <Route path="product4" element={<Product_with_related_products />} />
          <Route path="product5" element={<Product_with_tabs_and_related_products />} />

          <Route path="cart1" element={<Cart_simple_with_policy_grid />} />
          <Route path="cart2" element={<Cart_with_policy_grid_and_extended_summary />} />
          <Route path="cart3" element={<Cart_with_related_products />} />

          <Route path="store1" element={<Storefront_with_dark_nav_and_footer />} />
          <Route path="store2" element={<Storefront_with_image_tiles_and_feature_sections />} />
          <Route path="store3" element={<Storefront_with_offers_and_testimonials />} />
          <Route path="store4" element={<Storefront_with_overlapping_image_tiles_and_perks />} />

          /* Marketing */
          <Route path="about1" element={<About_dark />} />
          <Route path="about2" element={<About_with_image_tiles />} />
          <Route path="about3" element={<About_with_timeline_and_stats />} />
          
          <Route path="landing1" element={<Landing_with_background_image_hero_and_pricing_section />} />
          <Route path="landing2" element={<Landing_with_large_screenshot_and_testimonial />} />
          <Route path="landing3" element={<Landing_with_mobile_screenshot_and_testimonials_grid />} />
          <Route path="landing4" element={<Landing_with_screenshots_and_stats />} />

          <Route path="pricing1" element={<Pricing_with_comparison_table />} />
          <Route path="pricing2" element={<Pricing_with_four_tiers />} />
          <Route path="pricing3" element={<Pricing_with_three_tiers_and_testimonials />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
